import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from './BaseQuery'

export const umbracoContentApi = createApi({
  reducerPath: 'api',
  baseQuery: async (args, api, extraOptions) => {
    return await baseQuery(args, api, extraOptions)
  },
  //keepUnusedDataFor: 0, //don't cache
  tagTypes: ['Media'],
  endpoints: () => ({}) //end points ar esplitted per entity and injected, see Slices folder
})
