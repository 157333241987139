import DebugConfig from './DebugConfig'
import ProductionConfig from './ProductionConfig'

export default class AppConfig {
  //Constants
  static Service = ProductionConfig
  //static Service = DebugConfig

  public static get IsDebugMode() {
    //return true
    return AppConfig.Service === DebugConfig
  }
}
