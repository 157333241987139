import React, { useRef } from 'react'
import { NavLink } from 'react-router-dom'
import { Pages } from '../Screens/NavLayout'
import { Constants } from '../Models'
interface IProps {}

const Header: React.FC<IProps> = (props) => {
  //const navigate = useNavigate()

  const navBarCollapseRef = useRef<HTMLDivElement>(null)

  return (
    <nav className="navbar navbar-expand-md navbar-dark  p-3">
      <div className="container-fluid">
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarsExample04"
          aria-controls="navbarsExample04"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="container">
          <div
            className="collapse navbar-collapse"
            id="navbarsExample04"
            ref={navBarCollapseRef}
          >
            <ul className="navbar-nav me-auto mb-2 mb-md-0 ">
              <li className="nav-item my-2">
                <NavLink
                  to={Pages.Home.Path}
                  className="nav-link"
                  end
                  onClick={() => {
                    navBarCollapseRef.current?.classList.remove('show')
                  }}
                >
                  Home
                </NavLink>
              </li>
              <li className="nav-item my-2">
                <NavLink
                  to={Pages.Aanmelden.Path}
                  className="nav-link"
                  onClick={() => {
                    navBarCollapseRef.current?.classList.remove('show')
                  }}
                >
                  Aanmelden
                </NavLink>
              </li>
              <li className="nav-item my-2">
                <NavLink
                  to={Pages.Faq.Path}
                  className="nav-link"
                  onClick={() => {
                    navBarCollapseRef.current?.classList.remove('show')
                  }}
                >
                  Veelgestelde vragen
                </NavLink>
              </li>
              {/* <li className="nav-item dropdown my-2">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Halloween 2023
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li className="nav-item my-2">
                    <NavLink to={Pages.Faq.Path} className="nav-link">
                      Veelgestelde vragen
                    </NavLink>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={() => {
                        navigate(Pages.Faq.Path)
                      }}
                    >
                      faq
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Another action
                    </a>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Something else here
                    </a>
                  </li>
                </ul>
              </li> */}
              {/* <li className="nav-item my-2">
                <a href={Constants.MapLink} className="nav-link">
                  Kaart
                </a>
              </li> */}
              <li className="nav-item my-2">
                <NavLink
                  to={Pages.Kaart.Path}
                  className="nav-link"
                  onClick={() => {
                    navBarCollapseRef.current?.classList.remove('show')
                  }}
                >
                  Kaart
                </NavLink>
              </li>
              <li className="nav-item my-2">
                <a
                  href="https://www.facebook.com/groups/halloweenpiershil/"
                  className="nav-link"
                >
                  Facebook
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Header
