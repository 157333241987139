import React, { useEffect, PropsWithChildren, useRef } from 'react'

//provides a fade in effect
const BaseScreen: React.FC<PropsWithChildren> = ({ children }) => {
  const element = useRef<HTMLDivElement>(null)
  useEffect(() => {
    element?.current?.classList.remove('active')
    //fades in content
    setTimeout(() => {
      element?.current?.classList.add('active')
    }, 500)
  }, [null])

  //  return <>{children}</>

  return (
    <div className="content" ref={element}>
      {children}
    </div>
  )
}

export default BaseScreen
