import React from 'react'
import BaseScreen from '../../Components/BaseScreen'
import { ReplaceUmbracoConstants } from '../../Helpers/UmbracoHelpers'
import {
  useGetContentItemByPath,
  useSetInternalLinks
} from '../../Hooks/UmbracoHooks'
import { IAanmelden, IRoot } from '../../Models/Umbraco'
interface IProps {}

const About: React.FC<IProps> = (props) => {
  const { result: root, isLoading: rootIsLoading } =
    useGetContentItemByPath<IRoot>('/')
  const { result: aanmelden, isLoading } =
    useGetContentItemByPath<IAanmelden>('/aanmelden')

  //make link use react-router instead of direct browse
  useSetInternalLinks([aanmelden])

  if (rootIsLoading || isLoading) {
    return null
  }

  return (
    <BaseScreen>
      <div
        className="container shadow p-3 mb-5 rounded "
        dangerouslySetInnerHTML={{
          __html: ReplaceUmbracoConstants(
            aanmelden.properties.contentBlock.markup,
            root
          )
        }}
      />
      {/* <h2>Aanmelden</h2>
        <p>
          Wil je deel uitmaken van ons betoverende Halloween-avontuur? Meld je
          aan als aanbeladres door ons een e-mail met daarin uw <i>naam</i> en{' '}
          <i>adres</i> te sturen naar
          <a href="mailto:halloweenpiershil@outlook.com">
            {' '}
            halloweenpiershil@outlook.com
          </a>{' '}
          of stuur ons een berichtje via onze Facebook-pagina '
          <a href="https://www.facebook.com/groups/halloweenpiershil/">
            Halloween Piershil
          </a>
          '.
        </p>
        <p className="mb-5">
          Het opgeven van uw adres is niet verplicht maar op die manier kunnen
          we u toevoegen op de <a href={Pages.Kaart.Path}>kaart</a> en bent u
          voor alle kinderen goed te vinden.
        </p>
        <h2>Flyer</h2>
        <p>
          Verder, om ervoor te zorgen dat de trick-or-treaters je gemakkelijk
          kunnen vinden, willen we je vragen om in de week van zaterdag 28
          oktober <a href={Constants.FlyerLink}>onze flyer</a> voor je raam te
          hangen. Hiermee maak je je huis herkenbaar voor de kinderen die op
          zoek zijn naar lekkernijen en avontuur.
        </p>
        <p>
          De flyer wordt in de weken voor Halloween meebezorgd met het Kompas.
          Er liggen ook extra kopieën van de flyer bij de Heikoop supermarkt.
        </p> */}
      {/* </div> */}
    </BaseScreen>
  )
}

export default About
