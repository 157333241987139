import { DependencyList, useEffect } from 'react'
import {
  useGetContentItemByPathQuery,
  useGetContentQueryQuery
} from '../RTK/Slices/ContentSlice'
import { NavigateFunction, useNavigate } from 'react-router-dom'

export const useGetContentItemByPath = <T>(path: string) => {
  const { data, ...otherProps } = useGetContentItemByPathQuery(path)
  const result = data as T
  return { result, ...otherProps }
}

export const useGetContentQuery = <T>(query: string) => {
  const { data, ...otherProps } = useGetContentQueryQuery(query)
  const result = data as T
  return { result, ...otherProps }
}

export const useSetInternalLinks = (deps: DependencyList) => {
  const navigate = useNavigate()

  useEffect(() => {
    const internalLinks = window.document.getElementsByClassName('internal')

    //a function because, we want this bound to the button,  using arrow function this is always of the parent scope
    function clickHandler(this: HTMLAnchorElement, ev: MouseEvent) {
      const link = this
      const url = new URL(link.href)
      navigate(url.pathname)
      console.log(url.pathname)

      ev.preventDefault()
      return false
    }
    if (deps.filter((d) => d === undefined).length > 0) {
      return
    }

    for (let index = 0; index < internalLinks.length; index++) {
      const internalLink = internalLinks[index] as HTMLAnchorElement
      internalLink.addEventListener('click', clickHandler)
    }

    return () => {
      //console.log('unload')
      const internalLinks = window.document.getElementsByClassName('internal')
      for (let index = 0; index < internalLinks.length; index++) {
        const internalLink = internalLinks[index] as HTMLAnchorElement
        internalLink.removeEventListener('click', clickHandler)
      }
    }
  }, [...deps])
}
