import { Link, Outlet, Route, Routes } from 'react-router-dom'
import { AnimatedFooter } from '../Components/Footer/AnimatedFooter'
import Header from '../Components/Header'
import Paralax2 from '../Components/Footer/Paralax2'
import Paralax3 from '../Components/Footer/Paralax3'
import { IPage } from '../Models'
import Register from './About/Register'
import Faq from './Faq/Faq'
import Home from './Home/Home'
import Map from './Map/Map'

export const Pages: Record<string, IPage> = {
  Home: { Index: 0, Path: '/', GlowColor: '#fff' },
  Aanmelden: { Index: 1, Path: '/aanmelden', GlowColor: '#ff5003' },
  Faq: { Index: 2, Path: '/faq', GlowColor: '#ff5003' },
  Kaart: { Index: 0, Path: '/kaart', GlowColor: '#ff5003' }

  //green '#137302'
  //#9F29C8 paars
  //blauw  #0018f7
  //oranje ff5003
}

export default function NavLayout() {
  return (
    <>
      <Routes>
        <Route path={Pages.Home.Path} element={<Layout />}>
          <Route index element={<Home />} />
          <Route path={Pages.Aanmelden.Path} element={<Register />} />
          <Route path={Pages.Faq.Path} element={<Faq />} />
          <Route path={Pages.Kaart.Path} element={<Map />} />
          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>
    </>
  )
}

function Layout() {
  return (
    <>
      <div className="cover moon zIndex30"></div>
      <Header />
      <Paralax3 />
      <Paralax2 />
      <main className="px-4 ">
        <Outlet />
      </main>
      <AnimatedFooter />
    </>
  )
}

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  )
}
