import { IRoot } from '../Models/Umbraco'
import { GetHalloweenDateDutch } from './DateHelpers'

export const ReplaceUmbracoConstants = (text: string, root: IRoot) => {
  text = ReplaceHalloweenDate(text, root.properties.halloweenDate)
  text = ReplaceFlyerLink(text, root.properties.flyerLink)
  //text = TestInternalLinks(text)

  return text
}

export const ReplaceHalloweenDate = (text: string, halloweenDate: string) => {
  return text.replaceAll(
    '#HalloweenDate#',
    `<b>${GetHalloweenDateDutch(halloweenDate)}</b>`
  )
}

export const ReplaceFlyerLink = (text: string, flyerLink: string) => {
  return text.replaceAll('#FlyerLink#', flyerLink)
}

export const TestInternalLinks = (text: string) => {
  //return '<button class="internal">Boeeeee</button>'

  return '<a href="/aanmelden" class="internal">Aanmelden</a><br/><a href="/faq" class="internal">faq</a>'
}
