import { format } from 'date-fns'
import { nl } from 'date-fns/locale'

export const GetDayNumber = (date: string) => {
  return format(new Date(date), 'dd')
}

export const GetHalloweenDateDutch = (date: string) => {
  return format(new Date(date), 'EEEE dd MMMM', { locale: nl })
}

export const GetHalloweenDay = (date: string) => {
  return format(new Date(date), 'EEEE', { locale: nl })
}
