import { fetchBaseQuery } from '@reduxjs/toolkit/query'
import AppConfig from '../Config/AppConfig'

export const baseQuery = fetchBaseQuery({
  baseUrl: AppConfig.Service.BaseUrl,
  prepareHeaders: (headers, { getState }) => {
    // const userToken = (getState() as RootState).persisted.UserToken

    // if (userToken && userToken.AccessToken) {
    //   headers.set('authorization', `Bearer ${userToken.AccessToken}`)
    //   headers.set('X-Correlation-ID', `${Math.random() * 200}`)
    // }
    //headers.set('api-key', 'sdsd-sd')
    headers.set('content-type', 'application/json')
    //    headers.set('accept-language', getMyLanguage())

    return headers
  }
})
