import React from 'react'
import pumpkin from '../../Assets/Images/evil-pumpkin.png'
import maanHuis from '../../Assets/Images/maanHuis.png'
import witch from '../../Assets/Images/witch-img.png'
import BaseScreen from '../../Components/BaseScreen'

import { GetDayNumber, GetHalloweenDay } from '../../Helpers/DateHelpers'
import { ReplaceUmbracoConstants } from '../../Helpers/UmbracoHelpers'
import {
  useGetContentItemByPath,
  useSetInternalLinks
} from '../../Hooks/UmbracoHooks'
import { IHome, IRoot } from '../../Models/Umbraco'

interface IProps {}

const Home: React.FC<IProps> = (props) => {
  const { result: root, isLoading: rootIsLoading } =
    useGetContentItemByPath<IRoot>('/')
  const { result: home, isLoading: homeIsLoading } =
    useGetContentItemByPath<IHome>('/home')

  //UmracoContentView component which does this stuff

  //make link use react-router instead of direct browse
  useSetInternalLinks([home])

  if (rootIsLoading || homeIsLoading) {
    return null
  }
  //navigate()
  //navigation.location
  const dayNumber = GetDayNumber(root.properties.halloweenDate)
  const halloweenDay = GetHalloweenDay(root.properties.halloweenDate)

  return (
    <BaseScreen>
      <div className="container shadow pt-3 px-3 mb-5 rounded header-banner ">
        <div className="row animate__animated animate__fadeInUp">
          {/* <div className="row "> */}
          <div className="col-md-6 ms-auto text-center">
            <img
              className="witch-img d-none d-lg-block"
              src={witch}
              alt="Heks"
            />
            <h1>
              <span className="text-flicker">
                <span>{halloweenDay}</span>{' '}
                <span className="animation_offset">{dayNumber}</span>
                <span> Oktober</span>
                <span className="animation_offset"></span>
              </span>
              <br />
              <span className="text-flicker">
                <span className="animation_offset">Hall</span>
                <span>owee</span>
                <span className="animation_offset">n!</span>
              </span>
            </h1>
            <div
              dangerouslySetInnerHTML={{
                __html: ReplaceUmbracoConstants(
                  home.properties.introBlock.markup,
                  root
                )
              }}
            />
          </div>
          <div className="col-md-6 d-flex align-items-end">
            <img src={maanHuis} alt="Huis" />
            <div className="hb-pumpkin">
              <img src={pumpkin} alt="pumpkin" />
            </div>
          </div>
        </div>
      </div>

      {/* part 2 */}
      <div className="container  shadow p-3 p-md-5 mb-5 rounded ">
        <h2 className="text-center text-flicker heading-bg h2">
          <span>Uyt </span>
          <span className="animation_offset">den </span>
          <span>ouden </span>
          <span className="animation_offset">doosch.</span>
        </h2>
        <div className="ratio ratio-16x9">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/N7U2NGoBaxg?si=NnPqbN-3O-NjHn1r"
            title="Halloween 2019"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </BaseScreen>
  )
}

export default Home
