import React, { useEffect, useState } from 'react'

import witch from '../../Assets/Images/witch-img.png'
import BaseScreen from '../../Components/BaseScreen'
import { ReplaceUmbracoConstants } from '../../Helpers/UmbracoHelpers'
import {
  useGetContentItemByPath,
  useGetContentQuery
} from '../../Hooks/UmbracoHooks'
import { IRoot } from '../../Models/Umbraco'
import { IFaqQueryResult } from '../../Models/Umbraco/IFaqQueryResult'
import { IFaqQuestionsResult } from '../../Models/Umbraco/IFaqQuestionsResult'

interface IProps {}

const Faq: React.FC<IProps> = (props) => {
  const { result: root, isLoading: rootIsLoading } =
    useGetContentItemByPath<IRoot>('/')
  const { result: faqCategories, isLoading } =
    useGetContentQuery<IFaqQueryResult>('fetch=children:faq')

  const [activeTab, setActiveTab] = useState<string | undefined>(undefined)

  const { result: questions } = useGetContentQuery<IFaqQuestionsResult>(
    `fetch=children:${activeTab}`
  )

  useEffect(() => {
    //set inital first tab selected
    if (faqCategories !== undefined) {
      setActiveTab(faqCategories.items[0].route.path)
    }
  }, [faqCategories])

  if (rootIsLoading || isLoading) {
    return null
  }

  const renderTabs = () => {
    const tabs = faqCategories.items.map((cat, key) => {
      //todo: active tab
      const activeClassName = activeTab === cat.route.path ? 'active' : ''
      return (
        <li className="nav-item" role="presentation" key={key}>
          <button
            className={`nav-link ${activeClassName}`}
            type="button"
            role="tab"
            onClick={() => {
              setActiveTab(cat.route.path)
            }}
          >
            {cat.name}
          </button>
        </li>
      )
    })

    return (
      <ul className="nav nav-tabs" id="myTab" role="tablist">
        {tabs}
      </ul>
    )
  }

  const renderTabContent = () => {
    //
    if (questions === undefined) {
      return null
    }

    const questionsElements = questions.items.map((question, key) => (
      <li key={key}>
        <b>{question.name}</b>
        <div
          dangerouslySetInnerHTML={{
            __html: ReplaceUmbracoConstants(
              question.properties.answer.markup,
              root
            )
          }}
        />
      </li>
    ))

    return (
      <div className="tab-content tab-pane">
        <ul className="faq">
          {questionsElements}
          <li>
            <p>
              PS: Wij vinden Halloween geweldig maar dat vindt natuurlijk niet
              iedereen, om welke reden dan ook. <br />
              Respecteer elkaar in deze keuze en laat iedereen in zijn of haar
              waarde! Dat hopen wij andersom namelijk ook.{' '}
              <img
                className="icon"
                src="/content/img/icons/winking-face.png"
                alt="wink"
              />
            </p>
          </li>
        </ul>
      </div>
    )
  }

  return (
    <>
      <BaseScreen>
        <div className="container shadow p-3 mb-5 rounded text-center">
          <div className="">
            <img
              className="witch-img d-none d-lg-block"
              src={witch}
              alt="witch"
            />
            <h2>Veelgestelde vragen</h2>
            {renderTabs()}
            {renderTabContent()}

            {/* <ul className="nav nav-tabs" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="aanbeladres-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#aanbeladres"
                  type="button"
                  role="tab"
                  aria-controls="aanbeladres"
                  aria-selected="true"
                >
                  Aanbeladres
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="deelnemer-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#deelnemer"
                  type="button"
                  role="tab"
                  aria-controls="deelnemer"
                  aria-selected="false"
                >
                  Deelnemer
                </button>
              </li>
            </ul>
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="aanbeladres"
                role="tabpanel"
                aria-labelledby="aanbeladres-tab"
              >
                <ul className="faq">
                  <li>
                    <b>Wat is een aanbeladres?</b>
                    <p>
                      Dit is een adres waar kinderen tijdens Halloween mogen
                      aanbellen om te ‘trick or treaten’.
                    </p>
                  </li>
                  <li>
                    <b>
                      Wat wordt er van me verwacht als ik me opgeef als
                      aanbeladres?
                    </b>
                    <p>
                      Tijdens Halloween gaan de kinderen op pad om te ‘trick or
                      treaten’. Als u open doet, roepen de kinderen: 'trick or
                      treat!'.
                    </p>
                    <p>
                      Trick betekent dat de persoon die opendoet geplaagd gaat
                      worden, en treat betekent dat de persoon iets lekkers moet
                      geven, zodat hij of zij met rust gelaten wordt. De
                      bewoners van de huizen geven de kinderen dan ook vaak
                      snoepjes (‘a treat’), zodat ze niet geplaagd worden!
                    </p>
                  </li>
                  <li>
                    <b>Wanneer is het en hoelaat begint het?</b>
                    <p>
                      Dit jaar vieren we Halloween op{' '}
                      <b>{Constants.HalloweenDate}</b>. De kinderen kunnen vanaf{' '}
                      <b>19:00</b> aanbellen en Halloween duurt tot <b>21:30</b>
                      .
                    </p>
                  </li>
                  <li>
                    <b>Hoe meld ik me aan als aanbeladres?</b>
                    <p>
                      Door een mailtje te sturen naar{' '}
                      <a href="mailto:halloweenpiershil@outlook.com">
                        halloweenpiershil@outlook.com
                      </a>{' '}
                      of via een berichtje op onze facebook pagina{' '}
                      <a href="https://www.facebook.com/groups/halloweenpiershil/">
                        Halloween Piershil
                      </a>
                      .
                    </p>
                    <p>
                      Hang in de week van <b>{Constants.HalloweenDate}</b>{' '}
                      <a href={Constants.FlyerLink}>de flyer</a> voor uw raam.
                    </p>
                    <p>
                      Laat even weten wat uw adres is, op die manier kunnen we u
                      toevoegen op de plattegrond en bent u voor alle kinderen
                      goed te vinden (
                      <i>Dit is niet verplicht, maar wel zo leuk</i>).
                    </p>
                    <p>
                      Wij zullen deze plattegrond op onze facebook pagina bekend
                      maken.
                    </p>
                  </li>
                  <li>
                    <b>Moet ik me aanmelden om mee te doen?</b>
                    <p>
                      Dit is niet verplicht. U kunt ook meedoen zonder
                      aanmelding.
                    </p>
                    <p>
                      Hang dan <b>{Constants.HalloweenDate}</b>{' '}
                      <a href={Constants.FlyerLink}>de flyer</a> voor uw raam. U
                      kunt ook deelnemen door het snoep duidelijk zichtbaar in
                      de tuin te zetten, zo hoeft er niet per se aangebeld te
                      worden.
                    </p>
                  </li>
                  <li>
                    <b>Moet ik mijn tuin versieren?</b>
                    <p>
                      Ook hiervoor geldt: Het is niet verplicht maar het is wel
                      zo leuk! <img className="icon" src={smile} alt="smile" />
                    </p>
                  </li>
                  <li>
                    <b>Hoeveel kinderen kan ik verwachten?</b>
                    <p>
                      Wij kunnen u niet voorspellen hoeveel kinderen er langs
                      zullen komen, maar de afgelopen jaren waren dit er
                      ongeveer <b>150</b> kinderen
                    </p>
                  </li>
                </ul>
              </div>
              <div
                className="tab-pane fade"
                id="deelnemer"
                role="tabpanel"
                aria-labelledby="deelnemer-tab"
              >
                <ul className="faq">
                  <li>
                    <b>Moet ik me aanmelden als ik mee wil lopen?</b>
                    <p>
                      Nee, er is geen aanmelding nodig voor het meelopen tijdens
                      Halloween. Het aanmelden is alleen voor als je mee wilt
                      doen als aanbeladres.
                    </p>
                  </li>
                  <li>
                    <b>Wanneer is het en hoelaat begint het?</b>
                    <p>
                      Dit jaar vieren we Halloween op{' '}
                      <b>{Constants.HalloweenDate}</b>. De kinderen kunnen vanaf{' '}
                      <b>19:00</b> aanbellen en Halloween duurt tot <b>21:30</b>
                      .
                    </p>
                  </li>
                  <li>
                    <b>Hoe oud moet ik zijn om mee te lopen?</b>
                    <p>
                      Er is geen minimum of maximum leeftijd om mee te lopen,{' '}
                      <b>iedereen</b> mag meedoen.
                    </p>
                  </li>
                  <li>
                    <b>Wordt er een bepaalde route gelopen door het dorp?</b>
                    <p>
                      Nee, dat is het leuke van onze Halloween. Je mag{' '}
                      <b>zelf</b> bepalen hoe je de route loopt. Je kan gewoon
                      vanaf je eigen huis beginnen en op zoek gaan naar het
                      dichtstbijzijnde adres. Luister daarnaast ook goed naar
                      andere deelnemers die je tegenkomt, die weten vaak ook
                      waar er nog meer lekker snoep te halen is!
                    </p>
                  </li>
                  <li>
                    <b>Hoe herken ik een Halloween aanbeladres?</b>
                    <p>
                      De deelnemende aanbeladressen hebben{' '}
                      <a href={Constants.FlyerLink}>de flyer</a> voor het raam
                      raam hangen. Vaak zijn deze adressen ook nog leuk versierd
                      voor Halloween. PS. Als een huis <i>geen</i>{' '}
                      <a href={Constants.FlyerLink}>flyer</a> heeft hangen dan
                      mag je daar dus <i>niet aanbellen</i> voor Halloween.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <ul className="faq">
              <li>
                <p>
                  PS: Wij vinden Halloween geweldig maar dat vindt natuurlijk
                  niet iedereen, om welke reden dan ook. <br />
                  Respecteer elkaar in deze keuze en laat iedereen in zijn of
                  haar waarde! Dat hopen wij andersom namelijk ook.{' '}
                  <img className="icon" src={wink} alt="wink" />
                </p>
              </li>
            </ul> */}
          </div>
        </div>
      </BaseScreen>
    </>
  )
}

export default Faq
