import React, { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import paralax2 from '../../Assets/Footer/paralax2.svg'
import { findPageIndex } from '../../Helpers/PageHelpers'
import { useWindowSize } from '../../Hooks/MyHooks'
import './Paralax2.css'

interface IProps {}

const Paralax2: React.FC<IProps> = (props) => {
  const element = useRef<HTMLDivElement>(null)
  const paralax2Ref = useRef<SVGSVGElement>(null)
  const location = useLocation()
  const windowSize = useWindowSize()
  useEffect(() => {
    const index = findPageIndex(location.pathname)

    if (index > -1 && element.current && paralax2Ref.current) {
      const scrollWidth = windowSize.width / 5
      const scroll = index * scrollWidth * -1
      //console.log("scroll", scroll);
      paralax2Ref.current.style.width = `${windowSize.width * 1.5}px`
      element.current.style.transform = `translate(${scroll}px)`
    }
  }, [location, windowSize])
  return (
    <div className="bottom zIndex20">
      <div className="bottomContainer paralax2" ref={element}>
        <svg
          viewBox="0 0 361 83.43"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          ref={paralax2Ref}
        >
          <g style={{ fill: '#020A0C' }}>
            <g id="g18">
              <polygon
                points="30.08 34.88 31.66 4.79 35.01 0 38.36 4.79 39.45 34.9 30.08 34.88"
                id="polygon6"
              />
              <path
                d="M.21,25.85c6.2-1.92,14.27,.08,24.78,2.33s14.51,1.02,22.01,1.64c7.5,.61,13-.11,22.25,0,9.25,.11,16.5,3.61,32,4.61s25.96,2.84,36.48,1.3c10.52-1.55,28.52-3.05,38.77-1.05s25.75,5.65,37.25,5.45c11.5-.2,27.5-1.41,34-2.68s25.02-2.23,33.88,0c8.87,2.23,15.92,.91,19.77,.7,3.85-.21,11-3.71,20.17-3.21,9.17,.5,15.76-5.76,22.8-5.05s15.33,5.85,15.33,5.85l1.29,1.72v45.98H0L.21,25.85Z"
                id="path8"
              />
              <polygon
                points="236.94 45.05 238.52 14.96 241.87 10.17 245.22 14.96 246.31 45.07 236.94 45.05"
                id="polygon10"
              />
              <g id="g16" className="zerk1">
                <polygon
                  points="199.47 26.1 199.47 30.35 201.66 30.35 201.66 26.1 203.32 24.32 204.94 24.32 204.94 22.27 203.32 22.27 201.66 20.63 201.66 19.09 199.47 19.09 199.47 20.63 197.81 22.27 196.2 22.27 196.2 24.32 197.81 24.32 199.47 26.1"
                  id="polygon12"
                />
                <path
                  d="M203.9,32.44c-.06-1.33-1.2-2.26-1.98-2.72h-2.7c-.79,.46-1.93,1.39-1.98,2.72h-.65v8.67h7.97v-8.67h-.65Z"
                  id="path14"
                />
              </g>
            </g>
          </g>
        </svg>
      </div>
    </div>
  )
}

export default Paralax2
