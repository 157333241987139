import { configureStore } from '@reduxjs/toolkit'
// Or from '@reduxjs/toolkit/query/react'

import { umbracoContentApi } from '../RTK/UmbracoContentApi'

export const store = configureStore({
  reducer: {
    // Add the generated reducer as a specific top-level slice
    [umbracoContentApi.reducerPath]: umbracoContentApi.reducer
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(umbracoContentApi.middleware)
})
