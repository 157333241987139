import React, { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import paralax3 from '../../Assets/Footer/paralax3.svg'
import { findPageIndex } from '../../Helpers/PageHelpers'
import { useWindowSize } from '../../Hooks/MyHooks'
import './Paralax3.css'

interface IProps {}

const Paralax2: React.FC<IProps> = (props) => {
  const element = useRef<HTMLDivElement>(null)
  const location = useLocation()
  const paralax3Ref = useRef<SVGSVGElement>(null)
  const windowSize = useWindowSize()

  useEffect(() => {
    const index = findPageIndex(location.pathname)

    if (index > -1 && element.current && paralax3Ref.current) {
      const scrollWidth = windowSize.width / 10
      const scroll = index * scrollWidth * -1
      //console.log("scroll", scroll);
      paralax3Ref.current.style.width = `${windowSize.width * 1.5}px`
      element.current.style.transform = `translate(${scroll}px)`
    }
  }, [location, windowSize])
  return (
    <div className="bottom zIndex20">
      <div className="bottomContainer paralax3" ref={element}>
        {/* <img src={paralax3} ref={paralax3Ref} /> #082029*/}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          ref={paralax3Ref}
          viewBox="0 0 360 82.72"
        >
          <g style={{ fill: '#082029' }}>
            <g>
              <g>
                <polygon points="170.31 10.49 169.05 13.27 170.48 13.93 171.74 11.14 173.37 10.47 174.42 10.95 175.03 9.61 173.98 9.13 173.37 7.56 173.83 6.55 172.4 5.89 171.94 6.91 170.36 7.49 169.3 7 168.69 8.35 169.75 8.83 170.31 10.49" />
                <path d="M163.29,24.83l2.52-11.75s4.21-1.78,7.17,1.54l-2.52,11.75-7.17-1.54Z" />
              </g>
              <path d="M0,22.49s9.41-4.68,40.89-9.08c20.23-2.83,41.53,11.89,61.01,8.91,19.48-2.98,37.34-7.68,55.68-1.39,18.33,6.29,16.81,3.6,31.17,1.79,14.36-1.81,30.91,.76,53.54,5.39,22.63,4.63,45.14,1.27,57.91-4.52,12.77-5.79,59.81,1.96,59.81,1.96v57.17H0V22.49Z" />
              <g className="zerk2_1">
                <polygon points="133.91 3.87 144.72 3.39 144.72 5.6 133.91 6.28 133.91 3.87" />
                <polygon points="137.88 0 138.25 12.75 140.89 12.9 140.34 0 137.88 0" />
                <path d="M138.17,12.52c-.91,.5-2.27,1.72-2.61,2.83-.34,1.12-.29,5.62-.29,5.62h10.47s-.59-5.33-1.71-6.59c-1.12-1.26-3.33-1.86-3.33-1.86h-2.53Z" />
              </g>
              <g className="zerk2_2">
                <polygon points="229.92 11.91 220.35 11.48 220.35 13.44 229.92 14.04 229.92 11.91" />
                <polygon points="226.4 8.48 226.07 19.77 223.74 19.9 224.22 8.48 226.4 8.48" />
                <path d="M226.15,19.56c.8,.44,1.97,1.12,2.72,2.58,.47,.92,1.05,4.91,1.05,4.91h-10.47s.53-4.72,1.52-5.84,2.94-1.65,2.94-1.65h2.24Z" />
              </g>
              <g>
                <rect x="264.14" y="15.01" width="1.74" height="13.03" />
                <rect x="261.57" y="17.59" width="6.89" height="1.44" />
                <polygon points="261.47 28.04 268.46 28.04 268.46 31.6 261.18 31.6 261.47 28.04" />
              </g>
            </g>
          </g>
        </svg>
      </div>
    </div>
  )
}

export default Paralax2
