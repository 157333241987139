import { IPage } from '../Models'
import { Pages } from '../Screens/NavLayout'

export const findPageIndex = (pathName: string) => {
  let returnValue = -1
  Object.keys(Pages).forEach(function (key, index) {
    const page = Pages[key]

    if (page.Path === pathName) {
      returnValue = page.Index
      return
    }
  })

  return returnValue
}

export const findPage = (pathName: string): IPage | undefined => {
  let returnValue = undefined
  Object.keys(Pages).forEach(function (key, index) {
    const page = Pages[key]

    if (page.Path === pathName) {
      returnValue = page
      return
    }
  })

  return returnValue
}
