import { Provider } from 'react-redux'
import Basic from './Screens/NavLayout'
import { store } from './Store'

export default function App() {
  return (
    <Provider store={store}>
      <Basic />
    </Provider>
  )
}
