import { umbracoContentApi } from '../UmbracoContentApi'

const mediaSlice = umbracoContentApi.injectEndpoints({
  endpoints: (builder) => ({
    getContentItemById: builder.query<any, string>({
      query: (id) => `/umbraco/delivery/api/v1/content/item/${id}`
    }),
    getContentItemByPath: builder.query<any, string>({
      query: (path) => `/umbraco/delivery/api/v1/content/item/${path}`
    }),
    getContentQuery: builder.query<any, string>({
      query: (query) => `/umbraco/delivery/api/v1/content?${query}`
    })

    // authenticateConsumerUser: builder.mutation<
    //   IAuthenticateUserResponse,
    //   { username: string; password: string }
    // >({
    //   query: (request) => {
    //     const body = {
    //       ClientSecret: AppConfig.Service.Secret,
    //       UserName: request.username,
    //       Password: request.password
    //     }
    //     return {
    //       url: `/api/v1/authenticate/${AppConfig.Service.ClientId}/consumer/`,
    //       method: 'POST',
    //       body
    //     }
    //   }
    // }),

    //builder
  })
})

export const {
  useGetContentItemByIdQuery,
  useGetContentItemByPathQuery,
  useGetContentQueryQuery
} = mediaSlice
